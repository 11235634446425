export default {
  "sign-in-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Melden Sie sich bei Ihrem Konto an"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "sign-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "forgot-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "forgot-password-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden"])},
  "remember-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort gemerkt"])},
  "forgot-password-email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir haben Ihnen eine E-Mail geschickt, bitte prüfen Sie Ihren Posteingang"])},
  "reset-password-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie Ihr neues Passwort ein"])},
  "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "repeat-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wiederholen Sie Ihr neues Passwort"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "submissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen"])},
  "forms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulare"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollen"])},
  "add-submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Schadensmeldung"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft"])},
  "group-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppenbenutzer"])},
  "sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilienverwaltung"])},
  "sub-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie"])},
  "form-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular Nr."])},
  "submitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antragsteller"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzugefügt"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisiert"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktiviert"])},
  "submitted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingereicht"])},
  "under-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unter Überprüfung"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgeschlossen"])},
  "select-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular auswählen"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "add-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular hinzufügen"])},
  "form-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formularname"])},
  "add-field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld hinzufügen"])},
  "field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld"])},
  "field-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feldname"])},
  "field-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feldtyp"])},
  "field-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feld-Platzhalter"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahl"])},
  "textarea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textbereich"])},
  "radio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radio"])},
  "checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrollkästchen"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
  "add-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer hinzufügen"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
  "create-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einen neuen Benutzer anlegen"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
  "add-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft hinzufügen"])},
  "assign-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer zuweisen"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
  "group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaftsname"])},
  "select-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesellschaft auswählen"])},
  "select-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer auswählen"])},
  "add-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle hinzufügen"])},
  "role-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rollenname"])},
  "modules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "edit-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle bearbeiten"])},
  "add-document-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenttyp hinzufügen"])},
  "document-type-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenttypname"])},
  "user-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
  "submissions-today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen heute"])},
  "submissions-this-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen diese Woche"])},
  "submissions-this-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen diesen Monat"])},
  "Submissions-all-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schadensmeldungen alle Zeiten"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
  "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente"])},
  "add-document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument hochladen"])},
  "document-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentarten"])},
  "links": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link"])},
  "document-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentart"])},
  "document-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentname"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "select-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilienverwaltung auswählen"])},
  "select-sub-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie auswählen"])},
  "add-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilienverwaltung hinzufügen"])},
  "add-sub-sub-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immobilie hinzufügen"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "warn-delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sind Sie sicher, dass Sie Folgendes löschen möchten?"])},
  "form-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulartyp"])},
  "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diesen Bereich sind derzeit keine Daten verfügbar"])},
  "404-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seite nicht gefunden"])},
  "404-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ups, leider gibt es diese Seite nicht."])},
  "404-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Seite, auf der Sie sich befinden, ist möglicherweise fehlerhaft oder wurde von uns entfernt. Bitte gehen Sie zurück zur Startseite."])},
  "404-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "old-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Altes Passwort"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort ändern"])},
  "field-comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar"])},
  "updated-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich aktualisiert"])},
  "edited-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreich bearbeitet"])},
  "your-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Rolle"])},
  "your-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Status"])},
  "invalid-email-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültige E-Mail oder Passwort"])},
  "incorrect-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort ist falsch"])},
  "new-submission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Submission"])},
  "invalid-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateiname muss ohne Leerzeichen sein"])},
  "no-user-yet-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Benutzer in"])},
  "sub-group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Immobilienverwaltung"])},
  "users-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer in"])},
  "sub-sub-group-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Immobilie"])},
  "editing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Bearbeitung"])},
  "deleting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen"])},
  "adding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hinzufügen"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "submission-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "submission-form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formular"])},
  "duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplizieren"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "email-notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail-Benachrichtigungen"])}
}