<template>
  <button
    :style="style"
    type="button"
    class="flex items-center justify-center"
    :class="{
      'w-full': !small,
      'mr-2': small,
    }"
    @click="onCheck"
  >
    <div
      class="w-5 h-5 rounded flex items-center justify-center cursor-pointer"
      :class="{
        'bg-WAORANGE-500': modelValue,
        'border-2 border-gray-200': !modelValue,
      }"
    >
      <img v-if="modelValue" src="/check-icon.svg" alt="check" class="w-3" />
    </div>
  </button>
  <button
    v-if="label"
    class="text-gray-600 text-sm"
    type="button"
    @click="onCheck"
  >
    {{ label }}
  </button>
</template>

<script>
export default {
  data() {
    return { modelValue: this.value || false };
  },
  emits: ["onChange"],
  methods: {
    onCheck() {
      this.modelValue = !this.modelValue;
      this.$emit("onChange", this.name, this.opt, this.modelValue);
    },
  },
  props: ["value", "name", "opt", "label", "small", "style"],
};
</script>
