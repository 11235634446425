<template>
  <Loader v-if="formLoading" />
  <form :class="{ 'max-h-screen': modal }" v-else @submit.prevent="onSubmit">
    <div v-if="error" class="py-3 px-5 mb-3 rounded bg-red-50 text-red-400">
      <span>* {{ error }}</span>
    </div>

    <div class="mb-4 px-2" v-if="!submission">
      <select-input
        :placeholder="$t('select-form')"
        :data="getFormData('name')"
        v-model="form"
        size="full"
        :filterMode="true"
        :returnId="true"
        :isObject="true"
      />
    </div>

    <div v-if="selectedForm" class="px-2">
      <div class="mb-4" v-if="!submission">
        <div class="mb-4">
          <select-input
            :data="getSubgroupData([selectedForm.group])"
            :placeholder="$t('group-name')"
            v-model="group"
            size="full"
            :returnId="true"
            :isObject="true"
            :defaultVal="group.name"
          />
        </div>

        <div class="mb-4">
          <select-input
            :data="getSubgroupData(selectedForm.subgroups)"
            :placeholder="$t('sub-group')"
            v-model="subgroup"
            size="full"
            :filterMode="selectedForm.subgroups.length > 5"
            :returnId="true"
            :isObject="true"
            ref="subgroup"
          />
        </div>

        <select-input
          :data="getSubgroupData(subsubgroupData)"
          :placeholder="$t('sub-sub-group')"
          v-model="subsubgroup"
          size="full"
          :filterMode="selectedForm.subsubgroups.length > 5"
          :returnId="true"
          :isObject="true"
          ref="subsubgroup"
        />
      </div>

      <div
        class="mb-4"
        v-for="field in selectedForm?.formFields"
        :key="field._id"
      >
        <div
          v-if="
            field.type.toLowerCase() === 'text' ||
            field.type.toLowerCase() === 'email' ||
            field.type.toLowerCase() === 'number' ||
            field.type.toLowerCase() === 'password' ||
            field.type.toLowerCase() === 'date'
          "
        >
          <label
            class="mb-1 text-base inline-block text-gray-500"
            :for="field._id"
            >{{ field.name }}</label
          >
          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>

          <input
            :id="field._id"
            class="rounded w-full text-gray-600"
            :placeholder="field.placeholder"
            :type="field?.type"
            v-model="data[field?.name]"
          />
        </div>

        <div class="mb-4" v-if="field.type.toLowerCase() === 'select'">
          <label
            class="mb-1.5 inline-block text-base text-gray-500"
            :for="field._id"
            >{{ field.name }}</label
          >
          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>

          <select-input
            :placeholder="field.placeholder"
            :data="getSelectData(field?.options)"
            v-model="data[field?.name]"
            size="full"
          />
        </div>

        <div class="mb-4" v-if="field.type.toLowerCase() === 'textarea'">
          <label
            class="mb-1.5 inline-block text-gray-500 text-base"
            :for="field._id"
            >{{ field.name }}</label
          >
          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>

          <textarea
            :id="field._id"
            :name="field.name"
            cols="30"
            rows="2"
            v-model="data[field.name]"
            :placeholder="field.placeholder"
            class="rounded w-full"
          ></textarea>
        </div>

        <div v-if="field.type.toLowerCase() === 'radio'">
          <label class="mb-1.5 inline-block text-gray-500 text-base">{{
            field.name
          }}</label>
          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>

          <div
            class="mb-1 flex justify-start items-center"
            v-for="(radio, idx) in field.options.split('\n')"
            :key="idx"
          >
            <input
              :name="field.name"
              class="mr-3"
              :id="`${field.name}-${idx}`"
              type="radio"
              v-model="data[field.name]"
              :value="radio"
            />
            <label
              class="text-gray-600 text-sm"
              :for="`${field.name}-${idx}`"
              >{{ radio }}</label
            >
          </div>
        </div>

        <div v-if="field.type.toLowerCase() === 'checkbox'">
          <label class="mb-1.5 inline-block text-gray-600 text-base">{{
            field.name
          }}</label>
          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>

          <div
            class="mb-2 flex justify-start items-center"
            v-for="(checkbox, idx) in field.options.split('\n')"
            :key="idx"
          >
            <check-box
              :value="data[field.name]?.[checkbox]"
              @onChange="onChange"
              :name="field.name"
              :opt="checkbox"
              :label="checkbox"
              :small="true"
            ></check-box>
          </div>
        </div>

        <div v-if="field.type.toLowerCase() === 'file'">
          <label class="mb-1.5 inline-block text-gray-800">{{
            field.name
          }}</label>

          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>

          <div class="flex flex-wrap items-start gap-x-3">
            <div>
              <label
                :for="field._id"
                class="block bg-yellow-600 py-1.5 px-6 rounded text-white cursor-pointer"
              >
                <img src="/upload-icon.svg" alt="upload" />
              </label>

              <input
                hidden
                :id="field._id"
                class="rounded w-full"
                :type="field.type"
                @change="onFileSelect($event, field)"
              />
            </div>

            <template v-if="tempoFile[field.name]">
              <file-button
                v-for="(file, idx) in tempoFile[field.name]"
                :key="idx"
                @onClick="deleteTempoFile(field, file)"
                :file="file"
              />
            </template>

            <template v-if="uploads && uploads[field.name]">
              <template v-if="Array.isArray(uploads[field.name])">
                <file-button
                  v-for="(file, idx) in uploads[field.name]"
                  @onClick="deleteUploadedFile(field, file)"
                  :key="idx"
                  :file="file"
                />
              </template>

              <file-button
                v-else
                :file="uploads[field.name]"
                @onClick="deleteUploadedFile(field, file, (obj = true))"
              />
            </template>
          </div>
        </div>

        <div class="mb-4" v-if="field.type === 'Comment'">
          <label
            class="mb-1.5 inline-block text-base text-gray-500"
            :for="field._id"
            >{{ field.name }}</label
          >
          <p v-if="field.comment" class="mb-1.5 text-gray-600 text-xs">
            {{ field.comment }}
          </p>
        </div>
      </div>
    </div>

    <div class="flex relative items-center justify-end pt-2 gap-x-3 px-1">
      <button
        :disabled="loading || deleting"
        class="rounded text-white py-1.5 px-4 bg-WAORANGE-500 text-sm"
      >
        {{
          submission
            ? loading
              ? $t("editing")
              : $t("edit")
            : loading
            ? `Submitting`
            : $t("submit")
        }}
      </button>
    </div>
  </form>
</template>

<script>
import httpClient from "../../services/httpClient";
import { mapActions, mapGetters, mapMutations } from "vuex";

import SelectInput from "@/components/forms/SelectInput.vue";
import CheckBox from "@/components/forms/CheckBox.vue";
import Loader from "@/components/Loader.vue";
import FileButton from "@/components/submissions/FileButton.vue";

export default {
  computed: {
    ...mapGetters("forms", { formList: "list" }),
    ...mapGetters("forms", { formLoading: "loading" }),
  },
  components: { SelectInput, Loader, CheckBox, FileButton },

  async created() {
    if (!this.submission) await this.getForms();
  },

  data() {
    return {
      loading: false,
      selectedSubgroup: null,

      group: {
        id: "",
        name: "",
      },
      subgroup: { id: "", name: "" },
      subsubgroup: { id: "", name: "" },

      subsubgroupData: [],
      error: null,

      tempoFile: {},
      selectedForm: this.submission ? this.submission.form : null,

      form: {
        id: this.submission?.form?._id || "",
        name: this.submission?.form?.name || "",
      },

      data: this.submission?.data
        ? JSON.parse(JSON.stringify(this.submission?.data))
        : {},

      uploads: this.submission?.uploads
        ? JSON.parse(JSON.stringify(this.submission?.uploads))
        : null,
    };
  },

  emits: ["onClose"],
  methods: {
    ...mapActions("forms", ["getForms"]),
    ...mapMutations("submissions", [
      "addSubmission",
      "updateSubmission",
      "removeSubmission",
    ]),

    onFileSelect(e, field) {
      this.tempoFile[field.name] = this.tempoFile[field.name]
        ? [...this.tempoFile[field.name], e.target.files[0]]
        : [e.target.files[0]];
    },

    getShortFileName(name) {
      const ext = name.substr(name.lastIndexOf(".") + 1);
      const shortName = name.length > 8 ? `${name.substr(0, 8)}...` : name;
      return name.length > 8 ? shortName + ext : name;
    },

    deleteTempoFile(field, file) {
      this.tempoFile[field.name] = this.tempoFile[field.name].filter(
        (f) => f.name !== file.name
      );
    },

    deleteUploadedFile(field, file, obj) {
      if (obj) delete this.uploads[field.name];
      else
        this.uploads[field.name] = this.uploads[field.name].filter(
          (f) => f.url !== file.url
        );
    },

    async onSubmit() {
      if (this.error) this.error = null;

      try {
        this.loading = true;

        if (this.submission) {
          const fd = new FormData();
          fd.append("data", JSON.stringify(this.data));
          if (this.uploads) fd.append("uploads", JSON.stringify(this.uploads));

          // append file to form data
          for (const [key, val] of Object.entries(this.tempoFile)) {
            val.forEach((v) => {
              fd.append(key, v);
            });
          }

          const { data } = await httpClient.put(
            `/admin/submissions/${this.submission._id}`,
            fd,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );

          this.updateSubmission(data);
        } else {
          const fd = new FormData();
          fd.append("form", this.selectedForm._id);
          if (this.subgroup?.id) fd.append("subgroup", this.subgroup?.id);
          if (this.subsubgroup?.id)
            fd.append("sub_subgroup", this.subsubgroup?.id);
          fd.append("data", JSON.stringify(this.data));
          if (this.uploads) fd.append("uploads", JSON.stringify(this.uploads));

          // append file to form data
          for (const [key, val] of Object.entries(this.tempoFile)) {
            val.forEach((v) => {
              fd.append(key, v);
            });
          }

          const { data } = await httpClient.post(`/admin/submissions`, fd, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          this.addSubmission(data);
          if (this.onSuccess) this.$emit("onSuccess");
        }

        this.loading = false;
        this.$emit("onClose");
      } catch (err) {
        this.loading = false;
        this.error =
          err.response && err.response?.data?.error
            ? err.response?.data?.error
            : err.error || err.message;
      }
    },

    getFormData(name) {
      return this.formList.map((form) => ({
        id: form._id,
        value: form[name],
      }));
    },

    getSelectData(data) {
      return data.split("\n").map((val, idx) => ({
        id: idx,
        value: val,
      }));
    },

    onChange(name, key, changedValue) {
      this.data[name] = { ...this.data[name], [key]: changedValue };
    },

    getSubgroupData(sub) {
      return sub.map((s) => ({ id: s?._id, value: s?.name }));
    },
  },

  props: ["submission", "onSuccess", "modal"],
  watch: {
    async form(newValue) {
      if (newValue.id === "") return (this.selectedForm = null);

      const found = this.formList.find((form) => form._id === newValue.id);

      if (this.$refs.group) this.$refs.group.resetData();

      this.selectedForm = found;
      this.group = {
        id: found.group._id,
        name: found.group.name,
      };

      if (this.$refs.subgroup) this.$refs.subgroup.resetData();
      if (this.$refs.subsubgroup) this.$refs.subsubgroup.resetData();
    },

    subgroup(newValue) {
      this.$refs.subsubgroup.resetData();

      this.subsubgroupData = this.selectedForm.subsubgroups.filter(
        (s) => s?.subgroup === newValue.id
      );
    },
  },
};
</script>

<style scoped>
input,
textarea {
  border: none;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

input[type="radio"] {
  background-color: transparent;
  color: #e57200;
  transition: 0.2s ease-in;
}

input[type="radio"]:focus,
input[type="radio"]:active,
input[type="radio"]:checked {
  background-color: #e57200;
  box-shadow: none !important;
}

form {
  width: 80vw;
  /* max-height: 100vh; */
}

.uploaded {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (min-width: 640px) {
  form {
    width: 500px;
  }
}
</style>
